@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'

















































































































































































































































































































.v-card--disabled
  > *:not(.v-card__progress)
    opacity: 0.4
